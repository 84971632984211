import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Textarea } from '@chakra-ui/react';
import React from 'react';
import { ShipmentAction } from '../../actions';
import { AppContext } from '../../contexts';
import file1 from '../../payloads/1_shipment_CMA_COSU_MAEU_COSTS.json';
import file4 from '../../payloads/1_shipment_CMA_COSU_MAEU_ALLOC_WEEKLY.json';
import file5 from '../../payloads/1_shipment_CMA_COSU_MAEU_ALLOC_SPLIT.json';
import file2 from '../../payloads/2_shipments_CMA_COSU.json';
import file6 from '../../payloads/operations.json';
import file3 from '../../payloads/1_shipment_CMA.json';

const examples = [
  { file: file3, label: '1 shipment with CMDU (Testing Static scores)' },
  { file: file1, label: '1 shipment with CMDU MAEU COSU (Testing COSTS)' },
  { file: file4, label: '1 shipment with CMDU MAEU COSU (Testing ALLOC WEEKLY)' },
  { file: file5, label: '1 shipment with CMDU MAEU COSU (Testing ALLOC SPLIT)' },
  { file: file2, label: '1 shipment with CMDU + 1 shipment with COSU (Testing Static scores)' },
  { file: file6, label: 'Operations' },
];

type Props = {
  isOpen: boolean;
  onClose: () => void;
}

export default function ShipmentModal({ isOpen, onClose }: Props) {
  const { shipments, actions } = React.useContext(AppContext);
  const [code, setCode] = React.useState(JSON.stringify(shipments, null, 2));
  const [loading, setLoading] = React.useState(false);

  if (!isOpen) return null;

  const confirm = () => {
    setLoading(true);
    actions.dispatch.shipments({
      type: ShipmentAction.ADD,
      payload: JSON.parse(code)
    });
    setLoading(false);
    onClose();
  }

  const paste = () => {
    if (navigator.clipboard) {
      setLoading(true);
      navigator.clipboard.readText()
        .then(text => {
          try {
            setCode(text);
          } catch (error) {
            alert(error)
          } finally {
            setLoading(false);
          }
        })
        .catch(err => {
          console.error('Impossible to read clipboard : ', err);
        });
    } else {
      console.error('Clipboard API is not available');
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Payload</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack>
            <Button onClick={() => setCode(JSON.stringify([]))} colorScheme='blue'>Clear</Button>
            <Button onClick={paste} colorScheme='blue'>Paste</Button>
            <Select
              placeholder='Or select an example'
              onChange={(e) => {
                console.log(e.target.value)
                if(e.target.value.length >=  1){
                  setLoading(true);
                  setCode(e.target.value);
                  setLoading(false);
                }
              }}
            >
              {
                examples.map((example, idx) => (
                  <option
                    key={`example-${idx}`}
                    value={JSON.stringify(example.file)}
                  >
                    {example.label}
                  </option>
                ))
              }
            </Select>
          </Stack>
          <Textarea
            disabled
            value={code}
            mt={5}
            p={15}
            height={300}
            style={{
              fontSize: 12,
              backgroundColor: "#f5f5f5",
              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button disabled={loading} colorScheme='blue' mr={3} onClick={onClose}>
            Close
          </Button>
          <Button isLoading={loading} onClick={confirm} variant='ghost'>Confirm</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}