import { CriteriaAction } from "../actions";
import { Action, CriteriaWeights } from "../interfaces";

const cacheKey = 'criteria-data-v1';
const getInitialStates: CriteriaWeights = localStorage[cacheKey] ? JSON.parse(localStorage.getItem(cacheKey) || '') : {
  switchCount: 10,
  co2: 50,
  costs: 50,
  eta: 50,
  deltaBetweenEtaAndAta: 50,
  allocations: 50,
  etdWeek: 50,
  carrierSplitAllocation: 50,
  deltaBetweenCargoReadinessPlusInlandTransitTimePlusTerminalCutoffAndEtd: 50,
  routing: 50,
}

export const initialCriteriaState = getInitialStates;

export type CriteriaItem = { [key: string]: number };
export const criteriaReducer = (state: CriteriaWeights, action: Action<CriteriaAction, CriteriaItem>) => {
  let newState = state
  switch (action.type) {
    case CriteriaAction.UPDATE:
      newState = { ...newState, ...action.payload }
      break;
    default:
      throw new Error(`Unhandled CriteriaAction ${action.type}`);
  }

  localStorage.setItem(cacheKey, JSON.stringify(newState))
  return { ...newState };
}