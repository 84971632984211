import { Box, Button, Divider, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Stack, Text, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { AiOutlineDollarCircle } from 'react-icons/ai';
import { BiNetworkChart } from 'react-icons/bi';
import { GiAirplaneArrival, GiAirplaneDeparture } from 'react-icons/gi';
import { IoContract } from 'react-icons/io5';
import { MdCo2 } from 'react-icons/md';
import { PiTruckLight } from 'react-icons/pi';
import { TbDelta, TbFileDelta } from 'react-icons/tb';
import { TiFlowSwitch } from 'react-icons/ti';
import { AppContext } from '../../contexts';
import ShipmentModal from './ShipmentModal';

type Props = {}

export default function ToolBox({ }: Props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const { criteria, actions } = React.useContext(AppContext);

  const toggle = () => setIsOpen(!isOpen);
  return (
    <Box width={300}>
      <Box p={5}>
        <Text fontSize='2xl'>Payload</Text>
        <Tooltip label='Click to edit shipments payload' fontSize='md'>
          <Button onClick={toggle} colorScheme='teal' size='xs'>
            Edit Payload
          </Button>
        </Tooltip>
        <ShipmentModal isOpen={isOpen} onClose={toggle} />
      </Box>
      <Divider />
      <Box p={5}>
        <Text fontSize='2xl'>Criteria</Text>
        <Stack>
          <Tooltip label='Number of algo iteration' fontSize='md'>
            <Box>
              <Text fontSize='md'>Switch count ({criteria.switchCount})</Text>
              <Slider max={15} onChange={(val) => actions.updateCriteria({ switchCount: val })} aria-label='switchCount' value={criteria.switchCount}>
                <SliderTrack bg='red.100'>
                  <SliderFilledTrack bg='tomato' />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color='tomato' as={TiFlowSwitch} />
                </SliderThumb>
              </Slider>
            </Box>
          </Tooltip>
          <Tooltip label='Based on total_co2_g field (Pain CO2)' fontSize='md'>
            <Box>
              <Text fontSize="sm">CO2 ({criteria.co2})</Text>
              <Slider onChange={(val) => actions.updateCriteria({ co2: val })} aria-label='co2' value={criteria.co2}>
                <SliderTrack bg='red.100'>
                  <SliderFilledTrack bg='tomato' />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color='tomato' as={MdCo2} />
                </SliderThumb>
              </Slider>
            </Box>
          </Tooltip>
          <Tooltip label='Based on total_costs USD field (Pain Costs)' fontSize='md'>
            <Box>
              <Text fontSize="sm">Costs ({criteria.costs})</Text>
              <Slider onChange={(val) => actions.updateCriteria({ costs: val })} aria-label='costs' value={criteria.costs}>
                <SliderTrack bg='red.100'>
                  <SliderFilledTrack bg='tomato' />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color='tomato' as={AiOutlineDollarCircle} />
                </SliderThumb>
              </Slider>
            </Box>
          </Tooltip>
          <Tooltip label='Based on eta field and wished arrival date (Pain Delivery)' fontSize='md'>
            <Box>
              <Text fontSize="sm">ETA ({criteria.eta})</Text>
              <Slider onChange={(val) => actions.updateCriteria({ eta: val })} aria-label='eta' value={criteria.eta}>
                <SliderTrack bg='red.100'>
                  <SliderFilledTrack bg='tomato' />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color='tomato' as={GiAirplaneArrival} />
                </SliderThumb>
              </Slider>
            </Box>
          </Tooltip>
          <Tooltip label='Based on eta and average_delay_eta_ata fields (Pain Delivery)' fontSize='md'>
            <Box>
              <Text fontSize="sm">Delta between ETA and ATA ({criteria.deltaBetweenEtaAndAta})</Text>
              <Slider onChange={(val) => actions.updateCriteria({ deltaBetweenEtaAndAta: val })} aria-label='deltaBetweenEtaAndAta' value={criteria.deltaBetweenEtaAndAta}>
                <SliderTrack bg='red.100'>
                  <SliderFilledTrack bg='tomato' />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color='tomato' as={TbDelta} />
                </SliderThumb>
              </Slider>
            </Box>
          </Tooltip>
          <Tooltip label='Based on left_weekly_volume field (Pain Alloc (weekly)' fontSize='md'>
            <Box>
              <Text fontSize="sm">Allocations (Weekly) ({criteria.allocations})</Text>
              <Slider onChange={(val) => actions.updateCriteria({ allocations: val })} aria-label='allocations' value={criteria.allocations}>
                <SliderTrack bg='red.100'>
                  <SliderFilledTrack bg='tomato' />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color='tomato' as={IoContract} />
                </SliderThumb>
              </Slider>
            </Box>
          </Tooltip>
          <Tooltip label='Based on left_global_volume field (Pain Alloc (split))' fontSize='md'>
            <Box>
              <Text fontSize="sm">Allocations (Carriers split) ({criteria.carrierSplitAllocation})</Text>
              <Slider onChange={(val) => actions.updateCriteria({ carrierSplitAllocation: val })} aria-label='carrierSplitAllocation' value={criteria.carrierSplitAllocation}>
                <SliderTrack bg='red.100'>
                  <SliderFilledTrack bg='tomato' />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color='tomato' as={BiNetworkChart} />
                </SliderThumb>
              </Slider>
            </Box>
          </Tooltip>
          <Tooltip label='Based on week of etd field (Pain ETD Week)' fontSize='md'>
            <Box>
              <Text fontSize="sm">ETD Weekly ({criteria.etdWeek})</Text>
              <Slider onChange={(val) => actions.updateCriteria({ etdWeek: val })} aria-label='etdWeek' value={criteria.etdWeek}>
                <SliderTrack bg='red.100'>
                  <SliderFilledTrack bg='tomato' />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color='tomato' as={GiAirplaneDeparture} />
                </SliderThumb>
              </Slider>
            </Box>
          </Tooltip>
          <Tooltip label='Based on cargo_readiness date and of origin_to_pol, default_cutoff_date fields (Pain Delta origin to pol)' fontSize='md'>
            <Box>
              <Text fontSize="sm">Delta between cargo readiness and ETD ({criteria.deltaBetweenCargoReadinessPlusInlandTransitTimePlusTerminalCutoffAndEtd})</Text>
              <Slider onChange={(val) => actions.updateCriteria({ deltaBetweenCargoReadinessPlusInlandTransitTimePlusTerminalCutoffAndEtd: val })} aria-label='deltaBetweenCargoReadinessPlusInlandTransitTimePlusTerminalCutoffAndEtd' value={criteria.deltaBetweenCargoReadinessPlusInlandTransitTimePlusTerminalCutoffAndEtd}>
                <SliderTrack bg='red.100'>
                  <SliderFilledTrack bg='tomato' />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color='tomato' as={TbFileDelta} />
                </SliderThumb>
              </Slider>
            </Box>
          </Tooltip>
          <Tooltip label='Based on numbers of transhipments (Pain Routing)' fontSize='md'>
            <Box>
              <Text fontSize="sm">Routing ({criteria.routing})</Text>
              <Slider onChange={(val) => actions.updateCriteria({ routing: val })} aria-label='routing' value={criteria.routing}>
                <SliderTrack bg='red.100'>
                  <SliderFilledTrack bg='tomato' />
                </SliderTrack>
                <SliderThumb boxSize={6}>
                  <Box color='tomato' as={PiTruckLight} />
                </SliderThumb>
              </Slider>
            </Box>
          </Tooltip>
        </Stack>
      </Box >
    </Box >
  )
}