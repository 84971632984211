import { Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { BiSolidFactory, BiSolidShip, BiSolidTruck } from 'react-icons/bi';
import { Stage } from '../../interfaces';
import { colors } from '../../utils/colors';

const icons = {
  merchantOrigin: BiSolidFactory,
  preCarriage: BiSolidTruck,
  mainTransport: BiSolidShip,
  postCarriage: BiSolidTruck,
  merchantDestination: BiSolidFactory,
}

const convertToTitleCase = (str: string) => {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, match => match.toUpperCase());
};

type Props = {
  stage: Stage | null;
  type: 'merchantOrigin' | 'preCarriage' | 'mainTransport' | 'postCarriage' | 'merchantDestination';
}

export default function StageTag({ stage, type }: Props) {
  if (stage === null) return null;

  return (
    <Tag colorScheme={colors[type]} size="sm">
      <TagLeftIcon as={icons[type]} />
      <TagLabel>{convertToTitleCase(type)}</TagLabel>
    </Tag>
  )
}