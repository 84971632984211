import { Box, Card, CardBody, CardHeader, Flex, Grid, GridItem, Heading, Stack, StackDivider, Text } from '@chakra-ui/react'
import { ScoredRoute, Shipment } from '../../interfaces'
import RouteCard from '../RouteCard'
import { getWeekNumber } from '../../utils/dates';


type Props = {
  shipment: Shipment,
  totalScores: { totalDynamicScore: number, shipmentCount: number };
}

export default function ResultCard({ shipment, totalScores }: Props) {
  const routes: ScoredRoute[] = shipment.routes as ScoredRoute[];
  const totalStaticScores = routes.reduce((acc, route) => acc + route.scores.total, 0);
  const routeCount = routes.length;

  return (
    <Card>
      <CardHeader>
        <Heading size='md'>Buyco ref: {shipment.buycoRef}</Heading>
      </CardHeader>

      <CardBody>
        <Stack divider={<StackDivider />} spacing='4'>
          <Box>
            <Heading size='xs' textTransform='uppercase'>
              Dates:
            </Heading>
            <Flex width="50%" justify="space-between">
              <Text pt='2' fontSize='m'>
                Cargo readiness date:
              </Text>
              <Text pt='2' fontSize='l' fontWeight={700}>
                {
                  shipment.dates.cargoReadiness?.date && `(Week: ${getWeekNumber(shipment.dates.cargoReadiness?.date)}) - `
                }
                {shipment.dates.cargoReadiness?.date}
              </Text>
            </Flex>
            <Flex width="50%" justify="space-between">
              <Text pt='2' fontSize='m'>
                Pickup date:
              </Text>
              <Text pt='2' fontSize='l' fontWeight={700}>
                {
                  shipment.dates.wishedPickup && `(Week: ${getWeekNumber(shipment.dates.wishedPickup)}) - `
                }
                {shipment.dates.wishedPickup}
              </Text>
            </Flex>
            <Flex width="50%" justify="space-between">
              <Text pt='2' fontSize='m'>
                Departure date:
              </Text>
              <Text pt='2' fontSize='l' fontWeight={700}>
                {
                  shipment.dates.wishedDeparture && `(Week: ${getWeekNumber(shipment.dates.wishedDeparture)}) - `
                }
                {shipment.dates.wishedDeparture}
              </Text>
            </Flex>
            <Flex width="50%" justify="space-between">
              <Text pt='2' fontSize='m'>
                Arrival date:
              </Text>
              <Text pt='2' fontSize='l' fontWeight={700}>
                {
                  shipment.dates.wishedArrival && `(Week: ${getWeekNumber(shipment.dates.wishedArrival)}) - `
                }
                {shipment.dates.wishedArrival}
              </Text>
            </Flex>
            <Flex width="50%" justify="space-between">
              <Text pt='2' fontSize='m'>
                Delivery date:
              </Text>
              <Text pt='2' fontSize='l' fontWeight={700}>
                {
                  shipment.dates.wishedDelivery && `(Week: ${getWeekNumber(shipment.dates.wishedDelivery)}) - `
                }
                {shipment.dates.wishedDelivery}
              </Text>
            </Flex>
          </Box>
          <Box>
            <Heading size='xs' textTransform='uppercase'>
              Containers:
            </Heading>
            {
              shipment.containers.map((container, idx) => (
                <Flex key={`container-${idx}`} width="50%">
                  <Text pt='2' fontSize='sm'>
                    {container.count} X {container.type}/{container.size}
                  </Text>
                </Flex>
              ))
            }
          </Box>
          <Box>
            <Heading size='xs' textTransform='uppercase'>
              Routes:
            </Heading>
            <Grid templateColumns='repeat(3, 1fr)' gap={10}>
              {
                shipment.routes.map((route, idx) => {
                  return <GridItem key={`route-${idx}`}>
                    <RouteCard shipment={shipment} route={route as ScoredRoute} totalScores={{ ...totalScores, totalStaticScores, routeCount }} />
                  </GridItem>
                })
              }
            </Grid>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  )
}