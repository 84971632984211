import React, { useReducer } from "react";
import { CriteriaAction } from '../actions';
import { apiSort } from '../api';
import { AppState, CriteriaWeights, Payload, Shipment } from "../interfaces";
import { CriteriaItem, criteriaReducer, initialCriteriaState, initialShipmentState, shipmentReducer } from "../reducers";

let timeout: any;

export const defaultStates: AppState = {
  shipments: initialShipmentState,
  criteria: initialCriteriaState,
  results: [],
  actions: {
    dispatch: {
      shipments: () => { },
      criteria: () => { },
    },
    fetchResults: () => Promise.resolve([]),
    updateCriteria: () => { },
  }
}

export const AppContext = React.createContext(defaultStates);

export const AppProvider = ({ children }: { children: JSX.Element }) => {
  const [results, updateResults] = React.useState(defaultStates.results)

  const [shipments, dispatchShipmentAction] = useReducer(
    shipmentReducer,
    initialShipmentState
  );
  const [criteria, dispatchCriteriaAction] = useReducer(
    criteriaReducer,
    initialCriteriaState
  );

  const updateCriteria = (payload: CriteriaItem) => {
    clearTimeout(timeout)

    dispatchCriteriaAction({ type: CriteriaAction.UPDATE, payload });
    timeout = setTimeout(() =>
      fetchResults(payload), 500)
  }

  const fetchResults = async (crit: CriteriaWeights | object = {}) => {
    const payload: Payload = { shipments, criteriaWeights: { ...criteria, ...crit } }
    const response = await apiSort(payload);
    console.log({ response })
    const data: Shipment[] = response.data;

    updateResults([...data])
    return data;
  };

  const actions = {
    dispatch: {
      shipments: dispatchShipmentAction,
      criteria: dispatchCriteriaAction
    },
    updateCriteria,
    fetchResults
  };

  return <AppContext.Provider
    value={{ shipments, criteria, actions, results }}
  >
    {children}
  </AppContext.Provider>
}