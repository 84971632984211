import { RoutingLeg, Site } from '@buyco/api'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Card, CardBody, CardHeader, Flex, HStack, Heading, Image, Stack, StackDivider, Stat, StatArrow, StatGroup, StatHelpText, StatLabel, StatNumber, Text } from '@chakra-ui/react'
import { Schedule, ScheduleLeg, ScoredRoute, Shipment, Stage } from '../../interfaces'
import { getWeekNumber } from '../../utils/dates'
import { toHash } from '../../utils/string'
import StageTag from '../Tags/Stage'
import Unlocode from '../Unlocode'

type Props = {
  route: ScoredRoute,
  shipment: Shipment;
  totalScores: { totalDynamicScore: number, shipmentCount: number, routeCount: number, totalStaticScores: number };
}

const scheduleLegToRoutingLeg = (leg: ScheduleLeg): RoutingLeg => {
  return {
    from: {
      //@ts-ignore
      unlocation: { code: leg.pol, country: leg.pol.substring(0, 2) }
    },
    to: {
      //@ts-ignore
      unlocation: { code: leg.pod, country: leg.pod.substring(0, 2) }
    },
    transitTime: {
      //@ts-ignore
      unit: 'DAYS', value: leg.transitTime
    }
  }
}

const mainTransportToStage = (mainTransport: Schedule): Stage => {
  return {
    legs: mainTransport.legs.map(scheduleLegToRoutingLeg),
    startDate: mainTransport.etd,
    endDate: mainTransport.eta,
    transitTime: { unit: 'DAYS', value: mainTransport.transitTime }
  }
}

type TLeg = Site

const legs = (route: ScoredRoute): TLeg[] => {
  const mainLegs = route.stages.mainTransport.legs.map(scheduleLegToRoutingLeg);

  const allLegs = [
    ...route.stages.merchantHaulageAtOrigin?.legs || [],
    ...route.stages.preCarriage?.legs || [],
    ...mainLegs,
    ...route.stages.postCarriage?.legs || [],
    ...route.stages.merchantHaulageToDestination?.legs || [],
  ]

  return allLegs.reduce((acc: TLeg[], obj: RoutingLeg, i) => {
    if (i === 0) {
      return [obj.from, obj.to];
    } else {
      return acc.concat(obj.to);
    }
  }, []);
}

export default function RouteCard({ shipment, route, totalScores }: Props) {
  const dynamicAvg = (totalScores.totalDynamicScore / totalScores.shipmentCount)
  const staticAvg = (totalScores.totalStaticScores / totalScores.routeCount)
  const globalAvg = dynamicAvg + staticAvg;
  const globalScore = route.scores.total + route.dynamicScores.total;

  const sites = legs(route);
  const matchingCosts = route.costs.filter(cost => (
    shipment.containers.some(container => container.size == cost.containerSize && container.type == cost.containerType)
  ))
  return (
    <Card>
      <CardHeader>
        <Flex gap={5} alignItems="center">
          <Image
            boxSize='60px'
            objectFit='cover'
            src={route.carrier.logo || ''}
            alt={route.carrier.name}
          />
          <Heading size='md'>{route.carrier.name}</Heading>
        </Flex>
        <Text fontSize="xs" fontWeight={600}>{route.stages.mainTransport.rateId}</Text>
        {
          matchingCosts.length >= 1 && (
            <Flex flexDirection='column'>
              {
                matchingCosts.map((cost, idx) => (
                  <Flex key={`matching-cost-${toHash(JSON.stringify(route))}-${idx}`}>
                    <Text fontSize="xs" fontWeight={600}>
                      {cost.containerType} {cost.containerSize} - {cost.amount.currency} {cost.amount.amountCents}
                    </Text>
                  </Flex>
                ))
              }
            </Flex>
          )
        }
      </CardHeader>

      <CardBody>
        <Stack divider={<StackDivider />} spacing='4'>
          <Stat display='flex' justifyContent='center' textAlign='center'>
            <StatLabel>Global Score</StatLabel>
            <StatNumber>{globalScore}</StatNumber>
            <StatHelpText>
              <StatArrow type={globalAvg > globalScore ? 'increase' : 'decrease'} />
              Average: {globalAvg}
            </StatHelpText>
            <Text fontSize="xl" color='lightgray' fontWeight={800}>{route.stages.mainTransport.firstVesselName}</Text>
          </Stat>
          <StatGroup>
            <Stat>
              <StatLabel>Static Score</StatLabel>
              <StatNumber>{route.scores.total}</StatNumber>
              <StatHelpText>
                <StatArrow type={staticAvg > route.scores.total ? 'increase' : 'decrease'} />
                Average: {staticAvg}
              </StatHelpText>
              <StatHelpText>
                CO2: {route.scores.co2}
              </StatHelpText>
              <StatHelpText>
                Delivery: {route.scores.delivery}
              </StatHelpText>
              <StatHelpText>
                Delta origin to pol: {route.scores.deltaReadinessRoutingAndEtd}
              </StatHelpText>
              <StatHelpText>
                ETD week: {route.scores.etdWeek}
              </StatHelpText>
              <StatHelpText>
                Routing: {route.scores.routing}
              </StatHelpText>
            </Stat>

            <Stat>
              <StatLabel>Dynamic Score</StatLabel>
              <StatNumber>{route.dynamicScores.total}</StatNumber>
              <StatHelpText>
                <StatArrow type={dynamicAvg >= route.dynamicScores.total ? 'increase' : 'decrease'} />
                Average: {dynamicAvg}
              </StatHelpText>
              <StatHelpText>
                Alloc (split): {route.dynamicScores.allocsSplit}
              </StatHelpText>
              <StatHelpText>
                Alloc (weekly): {route.dynamicScores.allocsWeekly}
              </StatHelpText>
              <StatHelpText>
                Costs: {route.dynamicScores.costs}
              </StatHelpText>
            </Stat>
          </StatGroup>
          <Flex gap={3} justifyContent="space-around">
            <Flex flexDirection="column" alignItems="center">
              <Text size="l">ETD</Text>
              <Text size="lg" fontWeight={700}>{route.stages.mainTransport.etd}</Text>
              <Text fontSize="xs">Week {getWeekNumber(route.stages.mainTransport.etd)}</Text>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              <Text size="l">ETA</Text>
              <Text size="lg" fontWeight={700}>{route.stages.mainTransport.eta}</Text>
              <Text fontSize="xs">Week {getWeekNumber(route.stages.mainTransport.eta)}</Text>
            </Flex>
          </Flex>
          <HStack>
            <StageTag stage={route.stages.merchantHaulageAtOrigin} type="merchantOrigin" />
            <StageTag stage={route.stages.preCarriage} type="preCarriage" />
            <StageTag stage={mainTransportToStage(route.stages.mainTransport)} type="mainTransport" />
            <StageTag stage={route.stages.postCarriage} type="postCarriage" />
            <StageTag stage={route.stages.merchantHaulageToDestination} type="merchantDestination" />
          </HStack>
          <Box>
            <Flex>
              {
                sites.map((site, idx) => (
                  <Flex key={`route-${JSON.stringify(route)}-site-${idx}`} alignItems="center">
                    <Unlocode site={site} />
                    {(idx + 1) < sites.length && <ChevronRightIcon color='gray.500' />}
                  </Flex>
                ))
              }
            </Flex>
          </Box>
        </Stack>
      </CardBody>
    </Card>
  )
}