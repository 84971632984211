import Axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { Payload, Shipment } from './interfaces';

export const API_URL = process.env.REACT_APP_API_URL;

if (API_URL === undefined) throw new Error('REACT_APP_API_URL environment variable is required');

export const client = applyCaseMiddleware(Axios.create());

export const setJwt = () => {
  const params = new URLSearchParams(window.location.search);
  const jwt = params.get('token');
  if (jwt === null) return console.warn('jwt params was not received, Apis calls will probably fail');

  params.delete('token');
  client.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
};

export const listenPayload = (callback: (shipments: Shipment[]) => void) => {
  window.addEventListener('message', (event: MessageEvent<{ target: 'shipments-payload' | string, shipments: Shipment[] }>) => {
    //@ts-ignore
    console.log('event received', event)
    if (event.data.target == 'shipments-payload') {
      callback(event.data.shipments)
    }
  });
};

export const apiHealth = () => client.get(`${API_URL}/health`);
export const apiSort = (payload: Payload) => client.post(`${API_URL}/api/sort`, { ...payload });