import { Site } from '@buyco/api';
import { Box, Flex, Text } from '@chakra-ui/react';
import ReactCountryFlag from 'react-country-flag';

type Props = {
  site: Site;
}

export default function Unlocode({ site }: Props) {
  return (
    <Box>
      <Flex alignItems="center" gap={1}>
        <ReactCountryFlag countryCode={
          //@ts-ignore
          site.unlocation.country
        } />
        <Text>
          {site.unlocation.code}
        </Text>
      </Flex>
    </Box>
  )
}