import { ShipmentAction } from "../actions";
import { Action, Shipment } from "../interfaces";

const cacheKey = 'shipment-data-v1';
const getInitialStates = localStorage[cacheKey] ? JSON.parse(localStorage.getItem(cacheKey) || '') : []

export const initialShipmentState: Shipment[] = getInitialStates;

export const shipmentReducer = (state: Shipment[], action: Action<ShipmentAction, Shipment[]>) => {
  let newState = state
  switch (action.type) {
    case ShipmentAction.ADD:
      newState = [...action.payload]
      break;
    default:
      throw new Error(`Unhandled ShipmentAction ${action.type}`);
  }

  localStorage.setItem(cacheKey, JSON.stringify(newState))
  return [...newState];
}