import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Image, Spinner } from '@chakra-ui/react';
import React from 'react';
import { AppContext } from '../../contexts';
import { Route, ScoredRoute } from '../../interfaces';
import ResultCard from '../ResultCard';

type Props = {}

export default function Content({ }: Props) {
  const { results } = React.useContext(AppContext);
  const [isReady, setIsReady] = React.useState(false)
  const totalDynamicScore = results.reduce((acc, shipment) => {
    return acc + shipment.routes.reduce((acc: number, route: ScoredRoute) => acc + route.dynamicScores.total, 0);
  }, 0);

  React.useEffect(() => {
    setIsReady(true);
  }, [])

  if (!isReady) {
    return <Box display='flex' justifyContent='center' alignItems='center' width='100%'>
      <Spinner />
    </Box>
  }

  return (
    <Accordion allowMultiple width="100%" height="100vh" overflowX="scroll" p={5}>
      {
        results.map((shipment, idx) => {
          const bestRoute: Route = shipment.routes[0]
          return (
            <AccordionItem width="100%" key={`shipment-${idx}`}>
              <AccordionButton display='flex'>
                <Image
                  mr={3}
                  boxSize='60px'
                  objectFit='cover'
                  src={bestRoute.carrier.logo || ''}
                  alt={bestRoute.carrier.name}
                />
                <Box as="span" flex='1' textAlign='left'>
                  {shipment.buycoRef}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              <AccordionPanel>
                <ResultCard key={`card-${idx}`} shipment={shipment} totalScores={{ totalDynamicScore, shipmentCount: results.length }} />
              </AccordionPanel>
            </AccordionItem>
          )
        })
      }
    </Accordion>
  )
}