export function getWeekNumber(date: string) {
  const target = new Date(date);
  const firstDayOfWeek = 1;
  const yearStart = new Date(target.getFullYear(), 0, 1);
  const daysOffset = (firstDayOfWeek - yearStart.getDay() + 7) % 7;
  const weekStart = new Date(yearStart.getFullYear(), 0, 1 + daysOffset);
  //@ts-ignore
  const diff = (target - weekStart);
  const weekNumber = Math.ceil(diff / (7 * 24 * 60 * 60 * 1000));

  return weekNumber;
}